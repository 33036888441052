import React, { useRef, useState, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { calculatePosition, isOutsideProtectiveZone, PositionType } from '../../../utils/calculate-position';
import { Trigger, Content, TriggerIcon } from './floating-object.styled';
import { PaletteColorType } from '../../../theme/type/palette-type';

type FloatingObjectType = {
    trigger: React.ReactNode;
    content: React.ReactNode;
    position?: PositionType;
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
    arrowPosition?: number;
    size?: 'small' | 'medium';
    cursor?: boolean;
    block?: boolean;
    icon?: React.ReactNode | null;
    contentWrapper?: React.ReactNode;
    arrowColor?: PaletteColorType;
    protectiveZone?: number;
};

export const FloatingObject: React.FC<FloatingObjectType> = ({
    trigger,
    content,
    position = 'bottom',
    isVisible,
    setIsVisible,
    arrowPosition,
    size = 'medium',
    block = false,
    cursor = false,
    icon,
    arrowColor,
    protectiveZone = 0,
}) => {
    const [floatingPosition, setFloatingPosition] = useState<{
        top: number;
        left: number;
        adjustedPosition: PositionType;
    } | null>(null);

    const triggerRef = useRef<HTMLDivElement>(null);
    const floatingRef = useRef<HTMLDivElement>(null);

    const calculateFloatingPosition = useCallback(() => {
        if (floatingRef.current && triggerRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const floatingRect = floatingRef.current.getBoundingClientRect();

            const { top, left, adjustedPosition } = calculatePosition(position, triggerRect, floatingRect);

            setFloatingPosition({ top, left, adjustedPosition });
        }
    }, [position]);

    const handleMouseLeave = useCallback(
        (event: MouseEvent) => {
            if (triggerRef.current && floatingRef.current) {
                const triggerRect = triggerRef.current.getBoundingClientRect();
                const floatingRect = floatingRef.current.getBoundingClientRect();

                if (isOutsideProtectiveZone(event.clientX, event.clientY, triggerRect, floatingRect, protectiveZone)) {
                    setIsVisible(false);
                }
            }
        },
        [setIsVisible],
    );

    useEffect(() => {
        if (isVisible) {
            calculateFloatingPosition();
        }
    }, [isVisible, calculateFloatingPosition]);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('mousemove', handleMouseLeave);
        } else {
            document.removeEventListener('mousemove', handleMouseLeave);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseLeave);
        };
    }, [isVisible, handleMouseLeave]);

    useEffect(() => {
        if (isVisible) {
            calculateFloatingPosition();

            const handleResize = () => {
                calculateFloatingPosition();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
        return undefined;
    }, [isVisible, calculateFloatingPosition]);

    return (
        <>
            <Trigger ref={triggerRef} onMouseEnter={() => setIsVisible(true)} block={block} cursor={cursor}>
                {trigger}
                {icon && <TriggerIcon>{icon}</TriggerIcon>}
            </Trigger>
            {isVisible &&
                createPortal(
                    <Content
                        ref={floatingRef}
                        position={floatingPosition?.adjustedPosition || position}
                        arrowPosition={arrowPosition}
                        icon={!!icon}
                        size={size}
                        arrowColor={arrowColor}
                        style={{
                            top: floatingPosition?.top,
                            left: floatingPosition?.left,
                        }}
                    >
                        {content}
                    </Content>,
                    document.body,
                )}
        </>
    );
};
