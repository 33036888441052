import { ErrorStatusEnum } from '@app/common';
import {
    Configuration,
    SymbolsApi,
    TradesApi,
    AuthApi,
    LogoApi,
    CurrenciesApi,
    PortfoliosApi,
    PortfoliosIntegrationsApi,
    ProfitsApi,
    AdminStatisticsApi,
    InvestmentsApi,
    StripeApi,
    UsersApi,
    SymbolSplitsApi,
    TaxReportApi,
    StatisticsApi,
    SymbolDividendsApi,
    WatchlistsApi,
    TradesImportApi,
    TradesExportApi,
    SymbolAlternativesApi,
    LeaderboardApi,
    MailsApi,
    SymbolPriceAlertsApi,
    SymbolGrowthRatesApi,
    ResponseContext,
} from '../defs/api';
import { OpenAPIConfig } from './open-api-config';
import { CookieService } from './services/cookie-service';
import { JsonError } from '../errors/json-error';
import { routes } from '../config/routes';

const middleware = (cookieService: CookieService) => ({
    async post(context: ResponseContext): Promise<Response | void> {
        let json;

        try {
            json = await context.response.json();
        } catch (e) {
            throw new JsonError();
        }

        if (![200, 201, 202, 204].includes(context.response.status)) {
            if (
                (context.response.status === 401 && json.message !== ErrorStatusEnum.BadCredentials) ||
                json.message === ErrorStatusEnum.Unauthorized
            ) {
                cookieService.setJwtToken(null);
                window.location.replace(routes.web.index);
                return;
            }

            if (json && json.message) {
                throw new Error(json.message);
            }
            throw new Error(await context.response.text());
        }
    },
});

class ApiClient {
    public readonly configuration: Configuration;

    public readonly authController: AuthApi;

    public readonly portfolioController: PortfoliosApi;

    public readonly portfolioIntegrationController: PortfoliosIntegrationsApi;

    public readonly symbolController: SymbolsApi;

    public readonly tradeController: TradesApi;

    public readonly tradeImportController: TradesImportApi;

    public readonly tradeExportController: TradesExportApi;

    public readonly logoController: LogoApi;

    public readonly currencyController: CurrenciesApi;

    public readonly profitController: ProfitsApi;

    public readonly adminStatisticsController: AdminStatisticsApi;

    public readonly investmentController: InvestmentsApi;

    public readonly stripeController: StripeApi;

    public readonly userController: UsersApi;

    public readonly symbolSplitsController: SymbolSplitsApi;

    public readonly taxReportController: TaxReportApi;

    public readonly statisticsController: StatisticsApi;

    public readonly symbolDividendsController: SymbolDividendsApi;

    public readonly watchlistsController: WatchlistsApi;

    public readonly symbolAlternativesController: SymbolAlternativesApi;

    public readonly leaderboardController: LeaderboardApi;

    public readonly mailController: MailsApi;

    public readonly symbolPriceAlertController: SymbolPriceAlertsApi;

    public readonly symbolGrowthRateController: SymbolGrowthRatesApi;

    constructor(cookieService: CookieService, config?: Partial<OpenAPIConfig>) {
        this.configuration = new Configuration({
            accessToken: cookieService.getJwtToken(),
            basePath: config?.BASE ?? '',
            credentials: config?.CREDENTIALS ?? 'include',
        });

        const apiMiddleware = middleware(cookieService);

        this.authController = new AuthApi(this.configuration).withMiddleware(apiMiddleware);
        this.portfolioController = new PortfoliosApi(this.configuration).withMiddleware(apiMiddleware);
        this.portfolioIntegrationController = new PortfoliosIntegrationsApi(this.configuration).withMiddleware(
            apiMiddleware,
        );
        this.symbolController = new SymbolsApi(this.configuration).withMiddleware(apiMiddleware);
        this.tradeController = new TradesApi(this.configuration).withMiddleware(apiMiddleware);
        this.tradeImportController = new TradesImportApi(this.configuration).withMiddleware(apiMiddleware);
        this.tradeExportController = new TradesExportApi(this.configuration).withMiddleware(apiMiddleware);
        this.logoController = new LogoApi(this.configuration).withMiddleware(apiMiddleware);
        this.currencyController = new CurrenciesApi(this.configuration).withMiddleware(apiMiddleware);
        this.profitController = new ProfitsApi(this.configuration).withMiddleware(apiMiddleware);
        this.adminStatisticsController = new AdminStatisticsApi(this.configuration).withMiddleware(apiMiddleware);
        this.investmentController = new InvestmentsApi(this.configuration).withMiddleware(apiMiddleware);
        this.stripeController = new StripeApi(this.configuration).withMiddleware(apiMiddleware);
        this.userController = new UsersApi(this.configuration).withMiddleware(apiMiddleware);
        this.symbolSplitsController = new SymbolSplitsApi(this.configuration).withMiddleware(apiMiddleware);
        this.taxReportController = new TaxReportApi(this.configuration).withMiddleware(apiMiddleware);
        this.statisticsController = new StatisticsApi(this.configuration).withMiddleware(apiMiddleware);
        this.symbolDividendsController = new SymbolDividendsApi(this.configuration).withMiddleware(apiMiddleware);
        this.watchlistsController = new WatchlistsApi(this.configuration).withMiddleware(apiMiddleware);
        this.symbolAlternativesController = new SymbolAlternativesApi(this.configuration).withMiddleware(apiMiddleware);
        this.leaderboardController = new LeaderboardApi(this.configuration).withMiddleware(apiMiddleware);
        this.mailController = new MailsApi(this.configuration).withMiddleware(apiMiddleware);
        this.symbolPriceAlertController = new SymbolPriceAlertsApi(this.configuration).withMiddleware(apiMiddleware);
        this.symbolGrowthRateController = new SymbolGrowthRatesApi(this.configuration).withMiddleware(apiMiddleware);
    }
}

export default ApiClient;
