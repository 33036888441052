/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnrealizedAllocationsOverviewDto
 */
export interface UnrealizedAllocationsOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    invested: number;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    taxFreeValue: number;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    currencyImpactValue: number;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    profit: number;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    dividendsValue: number;
    /**
     * 
     * @type {string}
     * @memberof UnrealizedAllocationsOverviewDto
     */
    currency: string;
}

export function UnrealizedAllocationsOverviewDtoFromJSON(json: any): UnrealizedAllocationsOverviewDto {
    return UnrealizedAllocationsOverviewDtoFromJSONTyped(json, false);
}

export function UnrealizedAllocationsOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnrealizedAllocationsOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invested': json['invested'],
        'value': json['value'],
        'taxFreeValue': json['taxFreeValue'],
        'currencyImpactValue': json['currencyImpactValue'],
        'profit': json['profit'],
        'dividendsValue': json['dividendsValue'],
        'currency': json['currency'],
    };
}

export function UnrealizedAllocationsOverviewDtoToJSON(value?: UnrealizedAllocationsOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invested': value.invested,
        'value': value.value,
        'taxFreeValue': value.taxFreeValue,
        'currencyImpactValue': value.currencyImpactValue,
        'profit': value.profit,
        'dividendsValue': value.dividendsValue,
        'currency': value.currency,
    };
}

