import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ErrorStatusEnum, RedirectActionEnum } from '@app/common';
import { BaseDialog } from '../base-dialog';
import { ThirdPartyLogin } from './third-party-login/third-party-login';
import { useRootStore } from '../../../../providers/root-store-provider';
import { routes } from '../../../../config/routes';
import { ResetPasswordForm } from '../../forms/reset-password-form';
import { ResetPasswordDialogMobxDto } from '../../../../models/mobx/dtos/auth/reset-password-dialog-mobx-dto';
import { DialogAccountLinks } from './links/links';
import { IconUser } from '../../../app/icons/user';
import { IconButton } from '../../../app/icon/button/icon-button';

export interface ResetPasswordDialogInterface {
    action?: string;
    status?: string;
}

export const ResetPasswordDialog: React.FC<ResetPasswordDialogInterface> = observer(
    (props: ResetPasswordDialogInterface) => {
        const { t } = useTranslation();
        const router = useRouter();
        const rootStore = useRootStore();
        const { alertStore } = rootStore;
        const { authDialogStore } = rootStore.authStore;
        const { action, status } = props;

        useEffect(() => {
            if (action === RedirectActionEnum.ResetPassword) {
                authDialogStore.setResetPasswordActive();
            }
            if (status === ErrorStatusEnum.SignedUpDifferently) {
                alertStore.setErrorMessageByStatus(status as ErrorStatusEnum);
                authDialogStore.setLoginActive(true);
            }
        }, []);

        const onResetPassword = async (values: ResetPasswordDialogMobxDto) => {
            const { token } = router.query;
            if (!token) {
                rootStore.alertStore.setErrorMessageByStatus(ErrorStatusEnum.EmailResetPasswordTokenExpired);
                return;
            }
            try {
                await rootStore.authStore.resetPassword(String(token), values.password);
                await router.push(routes.web.index);
                authDialogStore.setLoginActive();
                rootStore.alertStore.setSuccessMessage(t('dialogs.resetPassword.successMessage'));
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                alertStore.setErrorMessageByStatus(e.message);
            }
        };

        return (
            <>
                <BaseDialog
                    opened={authDialogStore.activeModal === 'resetPassword'}
                    close={() => authDialogStore.closeDialog()}
                    icon={<IconButton icon={<IconUser />} variant={'primary'} />}
                    title={t('dialogs.resetPassword.title')}
                    content={
                        <>
                            <ResetPasswordForm onSubmit={onResetPassword} />

                            <DialogAccountLinks type={authDialogStore.activeModal} />

                            <ThirdPartyLogin />
                        </>
                    }
                />
            </>
        );
    },
);
