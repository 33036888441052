/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortfolioTradeDto,
    PortfolioTradeDtoFromJSON,
    PortfolioTradeDtoFromJSONTyped,
    PortfolioTradeDtoToJSON,
} from './PortfolioTradeDto';

/**
 * 
 * @export
 * @interface UnrealizedReportTradeDto
 */
export interface UnrealizedReportTradeDto {
    /**
     * 
     * @type {PortfolioTradeDto}
     * @memberof UnrealizedReportTradeDto
     */
    trade: PortfolioTradeDto;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedReportTradeDto
     */
    marketValue: number;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedReportTradeDto
     */
    holdingPeriodDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof UnrealizedReportTradeDto
     */
    taxFree: boolean;
    /**
     * 
     * @type {number}
     * @memberof UnrealizedReportTradeDto
     */
    profitLoss: number;
}

export function UnrealizedReportTradeDtoFromJSON(json: any): UnrealizedReportTradeDto {
    return UnrealizedReportTradeDtoFromJSONTyped(json, false);
}

export function UnrealizedReportTradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnrealizedReportTradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trade': PortfolioTradeDtoFromJSON(json['trade']),
        'marketValue': json['marketValue'],
        'holdingPeriodDays': json['holdingPeriodDays'],
        'taxFree': json['taxFree'],
        'profitLoss': json['profitLoss'],
    };
}

export function UnrealizedReportTradeDtoToJSON(value?: UnrealizedReportTradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trade': PortfolioTradeDtoToJSON(value.trade),
        'marketValue': value.marketValue,
        'holdingPeriodDays': value.holdingPeriodDays,
        'taxFree': value.taxFree,
        'profitLoss': value.profitLoss,
    };
}

