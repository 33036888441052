import React, { useState } from 'react';
import { IconHelpFilled } from '../icons/help-filled';
import { PositionType } from '../../../utils/calculate-position';
import { FloatingObject } from '../floating-object/floating-object';
import { Content, IconWrapper } from './tooltip.styled';

type TooltipProps = {
    children: React.ReactNode;
    content: React.ReactNode | string;
    position?: PositionType;
    size?: 'small' | 'medium';
    icon?: boolean;
    arrowPosition?: number;
    block?: boolean;
    cursor?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
    children,
    content,
    position = 'top-left',
    size = 'medium',
    icon = false,
    arrowPosition,
    block = false,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    return (
        <FloatingObject
            trigger={children}
            content={<Content size={size}>{content}</Content>}
            position={position}
            isVisible={isTooltipVisible}
            setIsVisible={setIsTooltipVisible}
            arrowPosition={arrowPosition}
            block={block}
            icon={
                icon && (
                    <IconWrapper>
                        <IconHelpFilled />
                    </IconWrapper>
                )
            }
            cursor={icon}
            size={size}
            arrowColor="secondary"
        />
    );
};
