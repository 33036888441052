import { makeAutoObservable, runInAction } from 'mobx';
import { ErrorStatusEnum } from '@app/common';
import type { RootStore } from './root-store';
import { PortfolioIntegrationMobxDto } from '../mobx/dtos/portfolio-integration/portfolio-integration-mobx-dto';
import i18n from '../../translation/i18n';

export class PortfolioIntegrationStore {
    rootStore: RootStore;

    portfolioIntegration: PortfolioIntegrationMobxDto = new PortfolioIntegrationMobxDto();

    trading212IntegrationOpened: boolean = false;

    trading212IntegrationSaved: boolean = false;

    trading212Timeout: NodeJS.Timeout | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async fetchPortfolioIntegration() {
        try {
            const portfolioIntegration =
                await this.rootStore.apiClient.portfolioIntegrationController.portfolioIntegrationControllerGetIntegration(
                    {
                        id: this.rootStore.portfolioStore.selectedPortfolio.id,
                    },
                );

            runInAction(() => {
                this.portfolioIntegration = PortfolioIntegrationMobxDto.create(portfolioIntegration);
                this.trading212IntegrationOpened = this.portfolioIntegration.trading212Token !== null;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        }
    }

    async toggleTrading212PortfolioIntegrationOpened() {
        this.trading212IntegrationOpened = !this.trading212IntegrationOpened;
        if (!this.trading212IntegrationOpened) {
            this.portfolioIntegration.trading212Token = null;
            await this.updateTrading212Integration();
        }
    }

    async updateTrading212Integration() {
        try {
            await this.rootStore.apiClient.portfolioIntegrationController.portfolioIntegrationControllerUpdateTrading212Integration(
                {
                    id: this.rootStore.portfolioStore.selectedPortfolio.id,
                    trading212IntegrationDto: this.portfolioIntegration,
                },
            );
            this.trading212IntegrationSaved = true;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        }
    }

    updateTrading212Token(trading212Token: string) {
        this.trading212IntegrationSaved = false;
        this.portfolioIntegration.setTrading212Token(trading212Token);

        if (this.trading212Timeout) {
            clearTimeout(this.trading212Timeout);
        }

        this.trading212Timeout = setTimeout(() => {
            this.updateTrading212Integration();
        });
    }

    async runTrading212Integration() {
        this.rootStore.tradeStore.tradeImportDialogStore.importResultMessage = null;
        this.rootStore.tradeStore.tradeImportDialogStore.importResultWarnings = [];

        try {
            this.rootStore.tradeStore.tradeImportDialogStore.startLoading();
            const response =
                await this.rootStore.apiClient.tradeImportController.tradeImportControllerRunTrading212Integration({
                    id: this.rootStore.portfolioStore.selectedPortfolio.id,
                });

            if (!response) {
                throw new Error(ErrorStatusEnum.NoResponseFromTradeImport);
            }

            if (response.error) {
                throw new Error(response.error);
            }

            if (response.importedRowsCount <= 0) {
                if (response.existingTradesCount > 0) {
                    this.rootStore.tradeStore.tradeImportDialogStore.importResultWarnings.push({
                        message: i18n.t(`pages.trades.allRowsExisted`),
                    });
                    return;
                }

                this.rootStore.tradeStore.tradeImportDialogStore.importResultWarnings.push({
                    message: i18n.t(`pages.trades.noValidRows`),
                });
            }

            let importedResultMessage = i18n.t(`pages.trades.imported.successfully`, {
                importedRowsCount: response.importedRowsCount,
            });
            if (response.existingTradesCount) {
                importedResultMessage += i18n.t(`pages.trades.imported.existed`, {
                    existingTradesCount: response.existingTradesCount,
                });
            }

            if (response.notFoundCurrencies.length) {
                this.rootStore.tradeStore.tradeImportDialogStore.importResultWarnings.push({
                    message: i18n.t(`pages.trades.notFoundCurrencies`, {
                        currencies: response.notFoundCurrencies.join(', '),
                    }),
                });
            }

            if (response.notFoundSymbols.length) {
                this.rootStore.tradeStore.tradeImportDialogStore.importResultWarnings.push({
                    message: i18n.t(`pages.trades.notFoundSymbols`, {
                        symbols: response.notFoundSymbols.join(', '),
                    }),
                    link: '/app/faq#nerozpoznane-symboly-v-importu',
                });
            }

            if (response.invalidRowDates) {
                this.rootStore.tradeStore.tradeImportDialogStore.importResultWarnings.push({
                    message: i18n.t(`pages.trades.invalidRowDates`, {
                        invalidRowDates: response.invalidRowDates,
                    }),
                });
            }

            runInAction(() => {
                this.rootStore.tradeStore.tradeImportDialogStore.importResultMessage = importedResultMessage;
                this.rootStore.tradeStore.tradeImportDialogStore.rootStore.portfolioStore.selectedPortfolio.setIsRecalculating(
                    true,
                );
                this.rootStore.tradeStore.tradeImportDialogStore.tradeStore.fetchTradeList();
                this.rootStore.portfolioValueCalculationInfoDialogStore.open();
            });
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.rootStore.tradeStore.tradeImportDialogStore.stopLoading();
            this.rootStore.tradeStore.tradeImportDialogStore.closeImportDialog();
        }
    }
}
