import { makeAutoObservable } from 'mobx';
import { UnrealizedReportTradeDto } from '../../../../../defs/api';
import { PortfolioTradeMobxDto } from '../portfolio-trade-mobx-dto';

export class UnrealizedReportTradeMobxDto implements UnrealizedReportTradeDto {
    public trade: PortfolioTradeMobxDto = new PortfolioTradeMobxDto();

    public marketValue: number = 0;

    public holdingPeriodDays: number = 0;

    public profitLoss: number = 0;

    public taxFree: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: UnrealizedReportTradeDto): UnrealizedReportTradeMobxDto {
        const mobxDto = new UnrealizedReportTradeMobxDto();
        return Object.assign(mobxDto, dto, { trade: PortfolioTradeMobxDto.create(dto.trade) });
    }

    static createFromArray(dtos: UnrealizedReportTradeDto[]): UnrealizedReportTradeMobxDto[] {
        return dtos.map((dto) => UnrealizedReportTradeMobxDto.create(dto));
    }
}
