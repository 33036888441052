/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RealizedBuyTradeDto,
    RealizedBuyTradeDtoFromJSON,
    RealizedBuyTradeDtoFromJSONTyped,
    RealizedBuyTradeDtoToJSON,
} from './RealizedBuyTradeDto';

/**
 * 
 * @export
 * @interface RealizedReportTradeDto
 */
export interface RealizedReportTradeDto {
    /**
     * 
     * @type {RealizedBuyTradeDto}
     * @memberof RealizedReportTradeDto
     */
    trade: RealizedBuyTradeDto;
    /**
     * 
     * @type {number}
     * @memberof RealizedReportTradeDto
     */
    holdingPeriodDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof RealizedReportTradeDto
     */
    taxFree: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealizedReportTradeDto
     */
    profitLoss: number;
}

export function RealizedReportTradeDtoFromJSON(json: any): RealizedReportTradeDto {
    return RealizedReportTradeDtoFromJSONTyped(json, false);
}

export function RealizedReportTradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealizedReportTradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trade': RealizedBuyTradeDtoFromJSON(json['trade']),
        'holdingPeriodDays': json['holdingPeriodDays'],
        'taxFree': json['taxFree'],
        'profitLoss': json['profitLoss'],
    };
}

export function RealizedReportTradeDtoToJSON(value?: RealizedReportTradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trade': RealizedBuyTradeDtoToJSON(value.trade),
        'holdingPeriodDays': value.holdingPeriodDays,
        'taxFree': value.taxFree,
        'profitLoss': value.profitLoss,
    };
}

