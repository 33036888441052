import styled, { css } from 'styled-components';
import { PositionType } from '../../../utils/calculate-position';
import { PaletteColorType } from '../../../theme/type/palette-type';

export const Trigger = styled.div.withConfig({
    shouldForwardProp: (prop) => !['block', 'cursor'].includes(prop),
})<{
    block: boolean;
    cursor: boolean;
}>`
    ${({ cursor, block }) => css`
        position: relative;
        display: ${block ? 'flex' : 'inline-flex'};
        cursor: ${cursor ? 'help' : 'unset'};
        gap: 1rem;
        align-items: center;
    `}
`;

export const TriggerIcon = styled.div`
    display: inline-flex;
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['position', 'icon', 'small', 'arrowPosition', 'arrowColor'].includes(prop),
})<{
    position?: PositionType;
    icon?: boolean;
    size: 'small' | 'medium';
    arrowPosition?: number;
    arrowColor?: PaletteColorType;
}>`
    ${({ theme, size, position, icon, arrowPosition, arrowColor }) => css`
        position: absolute;
        z-index: 9999;

        &:after {
            border: ${size === 'small' ? '0.5rem' : '0.8rem'} solid rgba(0, 0, 0, 0);
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        --arrow-color: ${arrowColor ? theme.palette.color[arrowColor].main : 'transparent'};

        --top-position: ${size === 'small' ? '-1rem' : '-1.6rem'};
        --bottom-position: ${size === 'small' ? '1rem' : '1.6rem'};

        ${icon &&
        css`
            --horizontal-position: ${size === 'small' ? '1rem' : '1.6rem'};
            --horizontal-position-negative: ${size === 'small' ? '-1rem' : '-1.6rem'};
        `}

        ${!icon &&
        css`
            --horizontal-position: 0rem;
            --horizontal-position-negative: 0rem;
        `}

        ${arrowPosition &&
        css`
            --arrow-position: ${arrowPosition}rem;
        `}

        ${!arrowPosition &&
        css`
            --arrow-position: ${size === 'small' ? '1.2rem' : '1.6rem'};
        `}

        ${position === 'bottom' &&
        css`
            transform: translateY(var(--bottom-position));

            &:after {
                left: 50%;
                transform: translateX(-50%);
                bottom: 100%;
                border-bottom-color: var(--arrow-color);
            }
        `}

        ${position === 'top' &&
        css`
            transform: translateY(var(--top-position));

            &:after {
                top: 100%;
                border-top-color: var(--arrow-color);
                left: 50%;
                transform: translateX(-50%);
            }
        `}

        ${position === 'top-left' &&
        css`
            transform: translate(var(--horizontal-position-negative), var(--top-position));

            &:after {
                top: 100%;
                border-top-color: var(--arrow-color);
                left: var(--arrow-position);
            }
        `}

        ${position === 'top-right' &&
        css`
            transform: translate(var(--horizontal-position), var(--top-position));

            &:after {
                top: 100%;
                border-top-color: var(--arrow-color);
                right: var(--arrow-position);
            }
        `}

        ${position === 'bottom-right' &&
        css`
            transform: translate(var(--horizontal-position), var(--bottom-position));

            &:after {
                right: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: var(--arrow-color);
            }
        `}

        ${position === 'bottom-left' &&
        css`
            transform: translate(var(--horizontal-position-negative), var(--bottom-position));

            &:after {
                left: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: var(--arrow-color);
            }
        `}
    `}
`;
