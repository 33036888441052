import { makeAutoObservable } from 'mobx';
import { RealizedAllocationsOverviewDto } from '../../../../defs/api';

export class RealizedAllocationsOverviewMobxDto implements RealizedAllocationsOverviewDto {
    invested: number = 0;

    value: number = 0;

    currencyImpactValue: number = 0;

    profit: number = 0;

    currency: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: RealizedAllocationsOverviewDto): RealizedAllocationsOverviewMobxDto {
        const mobxDto = new RealizedAllocationsOverviewMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: RealizedAllocationsOverviewDto[]): RealizedAllocationsOverviewMobxDto[] {
        return dtos.map((dto) => RealizedAllocationsOverviewMobxDto.create(dto));
    }
}
