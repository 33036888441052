/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RealizedAllocationsOverviewDto
 */
export interface RealizedAllocationsOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationsOverviewDto
     */
    invested: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationsOverviewDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationsOverviewDto
     */
    currencyImpactValue: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationsOverviewDto
     */
    profit: number;
    /**
     * 
     * @type {string}
     * @memberof RealizedAllocationsOverviewDto
     */
    currency: string;
}

export function RealizedAllocationsOverviewDtoFromJSON(json: any): RealizedAllocationsOverviewDto {
    return RealizedAllocationsOverviewDtoFromJSONTyped(json, false);
}

export function RealizedAllocationsOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealizedAllocationsOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invested': json['invested'],
        'value': json['value'],
        'currencyImpactValue': json['currencyImpactValue'],
        'profit': json['profit'],
        'currency': json['currency'],
    };
}

export function RealizedAllocationsOverviewDtoToJSON(value?: RealizedAllocationsOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invested': value.invested,
        'value': value.value,
        'currencyImpactValue': value.currencyImpactValue,
        'profit': value.profit,
        'currency': value.currency,
    };
}

