import styled, { css } from 'styled-components';
import { getOpacity } from '../../../theme/utils/get-rgb';

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size'].includes(prop),
})<{
    size: 'small' | 'medium';
}>`
    ${({ theme, size }) => css`
        max-width: 30rem;
        background: ${theme.palette.color.secondary.main};
        color: ${theme.palette.common.textInverse};
        border-radius: 0.8rem;
        text-align: center;
        text-transform: none;
        padding: ${size === 'medium' ? '1.6rem' : '0.8rem 1rem'};
        font-size: ${size === 'medium' ? theme.text.size.small.fontSize : 'unset'};
        line-height: ${size === 'medium' ? theme.text.size.medium.lineHeight : 'unset'};
        box-shadow: 0 0 0.6rem 0.2rem ${getOpacity(theme.palette.background.body, 0.3)};
    `}
`;

export const IconWrapper = styled.span`
    position: relative;
    display: inline-flex;
    color: inherit;

    i {
        width: 1.4rem;
        height: 1.4rem;
    }
`;
