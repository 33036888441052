/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PortfolioTypeEnum {
    User = 'user',
    Summary = 'summary'
}

export function PortfolioTypeEnumFromJSON(json: any): PortfolioTypeEnum {
    return PortfolioTypeEnumFromJSONTyped(json, false);
}

export function PortfolioTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioTypeEnum {
    return json as PortfolioTypeEnum;
}

export function PortfolioTypeEnumToJSON(value?: PortfolioTypeEnum | null): any {
    return value as any;
}

