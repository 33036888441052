import { darken, lighten } from 'polished';

export const color = {
    light: {
        base: {
            neutral: '#000000',
            overlay: '#231D61',
            white: '#ffffff',
            black: '#000000',
            border: '#EFEFEF',
            text: '#231D61',
            textMuted: '#8E91AF',
            textInverse: '#ffffff',
            light: '#ffffff',
            dark: '#231D61',
            lighten: '#FAFCFF',
            gray: '#8E91AF',
            primary: '#1D90F2',
            secondary: '#231D61',
            positive: '#15BE96',
            negative: '#FF697B',
            error: '#FF697B',
            success: '#15BE96',
            info: '#1D90F2',
            warning: '#FF9669',
            blue: '#3347FF',
            purple: '#6A6FF9',
            green: '#15BE96',
            google: '#DB4437',
            apple: '#000000',
            note: '#FFE1A6',
            bitcoin: '#f7931a',
            facebook: '#4267B2',
            instagram: '#C13584',
        },
        lighten: {
            white: '#ffffff',
            black: '#231D61',
            light: lighten(0.1, '#FAFCFF'),
            gray: lighten(0.2, '#8E91AF'),
            primary: lighten(0.1, '#33B6FF'),
            secondary: lighten(0.1, '#231D61'),
            positive: lighten(0.1, '#15BE96'),
            negative: lighten(0.1, '#FF697B'),
            error: lighten(0.1, '#FF697B'),
            success: lighten(0.1, '#15BE96'),
            info: lighten(0.1, '#1D90F2'),
            warning: lighten(0.1, '#FF9669'),
            blue: lighten(0.1, '#3347FF'),
            purple: lighten(0.1, '#6A6FF9'),
            green: lighten(0.1, '#15BE96'),
            google: lighten(0.1, '#DB4437'),
            apple: lighten(0.1, '#000000'),
            note: lighten(0.1, '#FFE1A6'),
            bitcoin: '#f7931a',
            facebook: lighten(0.1, '#4267B2'),
            instagram: lighten(0.1, '#C13584'),
        },
        light: {
            white: '#ffffff',
            black: '#231D61',
            gray: lighten(0.3, '#8E91AF'),
            primary: '#33B6FF',
            secondary: lighten(0.2, '#231D61'),
            positive: lighten(0.2, '#15BE96'),
            negative: lighten(0.1, '#FF697B'),
            error: lighten(0.2, '#FF697B'),
            success: lighten(0.45, '#15BE96'),
            info: lighten(0.4, '#1D90F2'),
            warning: lighten(0.25, '#FF9669'),
            blue: lighten(0.2, '#3347FF'),
            purple: lighten(0.2, '#6A6FF9'),
            green: lighten(0.2, '#15BE96'),
            google: lighten(0.2, '#DB4437'),
            apple: lighten(0.2, '#000000'),
            note: lighten(0.2, '#FFE1A6'),
            bitcoin: '#f7931a',
            facebook: lighten(0.2, '#4267B2'),
            instagram: lighten(0.2, '#C13584'),
        },
        darken: {
            white: '#ffffff',
            black: '#231D61',
            light: darken(0.1, '#FAFCFF'),
            gray: darken(0.1, '#8E91AF'),
            primary: darken(0.1, '#33B6FF'),
            secondary: darken(0.1, '#231D61'),
            positive: darken(0.1, '#15BE96'),
            negative: darken(0.1, '#FF697B'),
            error: darken(0.1, '#FF697B'),
            success: darken(0.1, '#15BE96'),
            info: darken(0.1, '#1D90F2'),
            warning: darken(0.1, '#FF9669'),
            blue: darken(0.1, '#3347FF'),
            purple: darken(0.05, '#6A6FF9'),
            green: darken(0.1, '#15BE96'),
            google: darken(0.1, '#DB4437'),
            apple: darken(0.1, '#000000'),
            note: darken(0.1, '#FFE1A6'),
            bitcoin: '#f7931a',
            facebook: darken(0.1, '#4267B2'),
            instagram: darken(0.1, '#C13584'),
        },
        dark: {
            white: '#ffffff',
            black: '#231D61',
            light: darken(0.2, '#FAFCFF'),
            gray: darken(0.2, '#8E91AF'),
            primary: '#058AFB',
            secondary: darken(0.2, '#231D61'),
            positive: darken(0.2, '#15BE96'),
            negative: darken(0.2, '#FF697B'),
            error: darken(0.2, '#FF697B'),
            success: darken(0.2, '#15BE96'),
            info: darken(0.2, '#1D90F2'),
            warning: darken(0.2, '#FF9669'),
            blue: darken(0.2, '#3347FF'),
            purple: darken(0.2, '#6A6FF9'),
            green: darken(0.2, '#15BE96'),
            google: darken(0.2, '#DB4437'),
            apple: darken(0.2, '#000000'),
            note: darken(0.2, '#FFE1A6'),
            bitcoin: '#f7931a',
            facebook: darken(0.2, '#4267B2'),
            instagram: darken(0.2, '#C13584'),
        },
        gradient: {
            base: {
                primary: 'linear-gradient(115deg, #0087FA -30%, #7EFFF0 240%)',
                negative: `linear-gradient(115deg, ${darken(0.3, '#FF697B')} -30%, ${darken(0.35, '#FF697B')} 80%)`,
                positive: 'linear-gradient(115deg, #15BE96 -30%, #7EFFF0 240%)',
            },
            lighten: {
                primary: 'linear-gradient(115deg, #15BE96 -30%, #7EFFF0 240%)',
            },
            darken: {
                primary: 'linear-gradient(90deg, #1D90F2 -15%, #1875FF 118%)',
            },
        },
    },
    dark: {
        base: {
            neutral: '#ffffff',
            overlay: '#67629D',
            white: '#ffffff',
            black: '#000000',
            border: '#302F40',
            text: '#ffffff',
            textMuted: '#8E91AF',
            textInverse: '#231D61',
            light: '#1C1B2F',
            dark: '#231D61',
            lighten: '#181729',
            gray: '#8E91AF',
            primary: '#1D90F2',
            secondary: '#C4BEFE',
            positive: '#16A180',
            negative: '#BE4949',
            error: '#A62E32',
            success: '#266858',
            info: '#405E77',
            warning: '#BC6039',
            blue: '#33B6FF',
            purple: '#6A6FF9',
            green: '#15BE96',
            google: '#DB4437',
            apple: '#ffffff',
            note: '#C1A46B',
            bitcoin: '#f7931a',
            facebook: '#4267B2',
            instagram: '#C13584',
        },
        lighten: {
            white: '#ffffff',
            black: '#231D61',
            light: lighten(0.1, '#FAFCFF'),
            gray: lighten(0.1, '#8E91AF'),
            primary: lighten(0.1, '#33B6FF'),
            secondary: lighten(0.1, '#C4BEFE'),
            positive: lighten(0.1, '#16A180'),
            negative: lighten(0.1, '#BE4949'),
            error: lighten(0.1, '#A62E32'),
            success: lighten(0.1, '#266858'),
            info: lighten(0.1, '#405E77'),
            warning: lighten(0.1, '#BC6039'),
            blue: lighten(0.1, '#3347FF'),
            purple: lighten(0.1, '#6A6FF9'),
            green: lighten(0.1, '#15BE96'),
            google: lighten(0.1, '#DB4437'),
            apple: lighten(0.1, '#ffffff'),
            note: lighten(0.1, '#C1A46B'),
            bitcoin: '#f7931a',
            facebook: lighten(0.1, '#4267B2'),
            instagram: lighten(0.1, '#C13584'),
        },
        light: {
            white: '#ffffff',
            black: '#231D61',
            gray: lighten(0.3, '#2B2B3E'),
            primary: '#33B6FF',
            secondary: lighten(0.2, '#C4BEFE'),
            positive: lighten(0.2, '#16A180'),
            negative: lighten(0.1, '#BE4949'),
            error: lighten(0.2, '#A62E32'),
            success: lighten(0.45, '#266858'),
            info: lighten(0.4, '#405E77'),
            warning: lighten(0.25, '#BC6039'),
            blue: lighten(0.2, '#33B6FF'),
            purple: lighten(0.14, '#6A6FF9'),
            green: lighten(0.2, '#15BE96'),
            google: lighten(0.2, '#DB4437'),
            apple: lighten(0.2, '#ffffff'),
            note: lighten(0.2, '#C1A46B'),
            bitcoin: '#f7931a',
            facebook: lighten(0.2, '#4267B2'),
            instagram: lighten(0.2, '#C13584'),
        },
        darken: {
            white: '#ffffff',
            black: '#231D61',
            light: darken(0.1, '#FAFCFF'),
            gray: darken(0.1, '#8E91AF'),
            primary: darken(0.1, '#33B6FF'),
            secondary: darken(0.1, '#C4BEFE'),
            positive: darken(0.1, '#16A180'),
            negative: darken(0.1, '#BE4949'),
            error: darken(0.1, '#A62E32'),
            success: darken(0.1, '#266858'),
            info: darken(0.1, '#405E77'),
            warning: darken(0.1, '#BC6039'),
            blue: darken(0.09, '#33B6FF'),
            purple: darken(0.03, '#6A6FF9'),
            green: darken(0.1, '#15BE96'),
            google: darken(0.1, '#DB4437'),
            apple: darken(0.1, '#ffffff'),
            note: darken(0.1, '#C1A46B'),
            bitcoin: '#f7931a',
            facebook: darken(0.1, '#4267B2'),
            instagram: darken(0.1, '#C13584'),
        },
        dark: {
            white: '#ffffff',
            black: '#231D61',
            light: darken(0.2, '#FAFCFF'),
            gray: darken(0.2, '#8E91AF'),
            primary: '#058AFB',
            secondary: darken(0.2, '#C4BEFE'),
            positive: darken(0.2, '#16A180'),
            negative: darken(0.2, '#BE4949'),
            error: darken(0.2, '#A62E32'),
            success: darken(0.2, '#266858'),
            info: darken(0.2, '#405E77'),
            warning: darken(0.2, '#BC6039'),
            blue: darken(0.2, '#3347FF'),
            purple: darken(0.2, '#6A6FF9'),
            green: darken(0.2, '#15BE96'),
            google: darken(0.2, '#DB4437'),
            apple: darken(0.2, '#ffffff'),
            note: darken(0.2, '#C1A46B'),
            bitcoin: '#f7931a',
            facebook: darken(0.2, '#4267B2'),
            instagram: darken(0.2, '#C13584'),
        },
        gradient: {
            base: {
                primary: 'linear-gradient(115deg, #0087FA -30%, #7EFFF0 240%)',
                negative: 'linear-gradient(115deg, #FF697B -30%, #ffffff 240%)',
                positive: 'linear-gradient(115deg, #15BE96 -30%, #7EFFF0 240%)',
            },
            lighten: {
                primary: 'linear-gradient(115deg, #0087FA -30%, #7EFFF0 240%)',
            },
            darken: {
                primary: 'linear-gradient(90deg, #1D90F2 -15%, #1875FF 118%)',
            },
        },
    },
};
