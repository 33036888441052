export type PositionType = 'bottom' | 'top' | 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';

export const calculatePosition = (
    position: PositionType,
    triggerRect: DOMRect,
    floatingRect: DOMRect,
    viewportWidth: number = window.innerWidth,
    viewportHeight: number = window.innerHeight,
    scrollX: number = window.scrollX,
    scrollY: number = window.scrollY,
): { top: number; left: number; adjustedPosition: PositionType } => {
    let top = 0;
    let left = 0;
    let adjustedPosition = position;

    const calculate = (pos: PositionType) => {
        switch (pos) {
            case 'bottom':
                top = triggerRect.bottom + scrollY;
                left = triggerRect.left + triggerRect.width / 2 - floatingRect.width / 2 + scrollX;
                break;
            case 'top':
                top = triggerRect.top - floatingRect.height + scrollY;
                left = triggerRect.left + triggerRect.width / 2 - floatingRect.width / 2 + scrollX;
                break;
            case 'top-left':
                top = triggerRect.top - floatingRect.height + scrollY;
                left = triggerRect.left + scrollX;
                break;
            case 'top-right':
                top = triggerRect.top - floatingRect.height + scrollY;
                left = triggerRect.right - floatingRect.width + scrollX;
                break;
            case 'bottom-left':
                top = triggerRect.bottom + scrollY;
                left = triggerRect.left + scrollX;
                break;
            case 'bottom-right':
                top = triggerRect.bottom + scrollY;
                left = triggerRect.right - floatingRect.width + scrollX;
                break;
            default:
                top = triggerRect.bottom + scrollY;
                left = triggerRect.left + triggerRect.width / 2 - floatingRect.width / 2 + scrollX;
                break;
        }
    };

    // Initial calculation
    calculate(position);

    // Backup original position
    const originalTop = top;
    const originalLeft = left;

    // Vertical overflow detection
    if (top + floatingRect.height > viewportHeight + scrollY) {
        if (adjustedPosition.includes('bottom')) {
            adjustedPosition = adjustedPosition.replace('bottom', 'top') as PositionType;
            calculate(adjustedPosition);
        }
    } else if (top < scrollY) {
        if (adjustedPosition.includes('top')) {
            adjustedPosition = adjustedPosition.replace('top', 'bottom') as PositionType;
            calculate(adjustedPosition);
        }
    }

    // Horizontal overflow detection
    if (left + floatingRect.width > viewportWidth + scrollX) {
        if (adjustedPosition.includes('right')) {
            adjustedPosition = adjustedPosition.replace('right', 'left') as PositionType;
            calculate(adjustedPosition);
        }
    } else if (left < scrollX) {
        if (adjustedPosition.includes('left')) {
            adjustedPosition = adjustedPosition.replace('left', 'right') as PositionType;
            calculate(adjustedPosition);
        }
    }

    // Final check: If still overflowing, fall back to original position
    if (
        top + floatingRect.height > viewportHeight + scrollY ||
        top < scrollY ||
        left + floatingRect.width > viewportWidth + scrollX ||
        left < scrollX
    ) {
        top = originalTop;
        left = originalLeft;
        adjustedPosition = position; // Reset to original position
    }

    return { top, left, adjustedPosition };
};

export const isOutsideProtectiveZone = (
    mouseX: number,
    mouseY: number,
    triggerRect: DOMRect,
    floatingRect: DOMRect,
    protectiveZone: number,
): boolean => {
    // Rozšíříme ochrannou zónu kolem Trigger a Content
    const triggerProtectiveRect = {
        top: triggerRect.top - protectiveZone,
        left: triggerRect.left - protectiveZone,
        bottom: triggerRect.bottom + protectiveZone,
        right: triggerRect.right + protectiveZone,
    };

    const contentProtectiveRect = {
        top: floatingRect.top - protectiveZone,
        left: floatingRect.left - protectiveZone,
        bottom: floatingRect.bottom + protectiveZone,
        right: floatingRect.right + protectiveZone,
    };

    // Zkontrolujeme, zda je myš mimo obě zóny
    const isOutsideTrigger =
        mouseX < triggerProtectiveRect.left ||
        mouseX > triggerProtectiveRect.right ||
        mouseY < triggerProtectiveRect.top ||
        mouseY > triggerProtectiveRect.bottom;

    const isOutsideContent =
        mouseX < contentProtectiveRect.left ||
        mouseX > contentProtectiveRect.right ||
        mouseY < contentProtectiveRect.top ||
        mouseY > contentProtectiveRect.bottom;

    // Floating zmizí, pokud je kurzor mimo obě zóny
    return isOutsideTrigger && isOutsideContent;
};
