import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconHidePassword: React.FC = () => (
    <Icon viewBox="0 0 128 128" fill="none">
        <path
            d="M64 28C39.4 28 18.4 46.6 12 64c6.4 17.4 27.4 36 52 36s45.2-18.6 52-36c-6.4-17.4-27.4-36-52-36zm0 60c-14.4 0-26-11.6-26-26s11.6-26 26-26 26 11.6 26 26-11.6 26-26 26z"
            fill="currentColor"
            strokeWidth="2"
        />
        <path d="M25 25l78 78M25 103l78-78" stroke="currentColor" strokeWidth="6" strokeLinecap="round" />
    </Icon>
);
