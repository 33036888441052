import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import { StyledTextField } from './textfield.styled';
import { TextFieldProps } from './textfield.type';
import { IconButton } from '../../icon/button/icon-button';
import { IconHidePassword } from '../../icons/hide-password';
import { IconShowPassword } from '../../icons/show-password';

export const PasswordField = (props: TextFieldProps) => {
    const { ...rest } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <StyledTextField
            size={props.size}
            radius={props.radius}
            width={props.width}
            type={showPassword ? 'text' : 'password'}
            {...rest}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            icon={showPassword ? <IconHidePassword /> : <IconShowPassword />}
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};
