import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createText = (t: ThemeEnum) => {
    const text: DefaultTheme['text'] = {
        size: {
            xsmall: {
                fontSize: '1.1rem',
                lineHeight: '1.5rem',
            },
            vsmall: {
                fontSize: '1.2rem',
                lineHeight: '1.6rem',
            },
            small: {
                fontSize: '1.3rem',
                lineHeight: '1.7rem',
            },
            medium: {
                fontSize: '1.4rem',
                lineHeight: '1.8rem',
            },
            large: {
                fontSize: '1.5rem',
                lineHeight: '2.0rem',
            },
            vlarge: {
                fontSize: '1.6rem',
                lineHeight: '2.2rem',
            },
            xlarge: {
                fontSize: '1.7rem',
                lineHeight: '3rem',
            },
            xxlarge: {
                fontSize: '2.4rem',
                lineHeight: '3.2rem',
            },
        },
        variant: {
            default: {
                text: color[t].base.text,
                opacity: {
                    low: getOpacity(color[t].base.secondary, 0.5),
                    medium: getOpacity(color[t].base.secondary, 0.24),
                    high: getOpacity(color[t].base.secondary),
                },
            },
            inverse: {
                text: color[t].base.textInverse,
                opacity: {
                    low: getOpacity(color[t].base.textInverse, 0.5),
                    medium: getOpacity(color[t].base.textInverse, 0.24),
                    high: getOpacity(color[t].base.textInverse),
                },
            },
            primary: {
                text: color[t].base.primary,
                opacity: {
                    low: getOpacity(color[t].base.primary, 0.5),
                    medium: getOpacity(color[t].base.primary, 0.24),
                    high: getOpacity(color[t].base.primary),
                },
            },
            secondary: {
                text: color[t].base.secondary,
                opacity: {
                    low: getOpacity(color[t].base.secondary, 0.5),
                    medium: getOpacity(color[t].base.secondary, 0.24),
                    high: getOpacity(color[t].base.secondary),
                },
            },
            positive: {
                text: color[t].base.positive,
                opacity: {
                    low: getOpacity(color[t].base.positive, 0.5),
                    medium: getOpacity(color[t].base.positive, 0.24),
                    high: getOpacity(color[t].base.positive),
                },
            },
            negative: {
                text: color[t].base.negative,
                opacity: {
                    low: getOpacity(color[t].base.negative, 0.5),
                    medium: getOpacity(color[t].base.negative, 0.24),
                    high: getOpacity(color[t].base.negative),
                },
            },
            warning: {
                text: color[t].base.warning,
                opacity: {
                    low: getOpacity(color[t].base.warning, 0.5),
                    medium: getOpacity(color[t].base.warning, 0.24),
                    high: getOpacity(color[t].base.warning),
                },
            },
            gray: {
                text: color[t].base.textMuted,
                opacity: {
                    low: getOpacity(color[t].base.gray, 0.5),
                    medium: getOpacity(color[t].base.gray, 0.24),
                    high: getOpacity(color[t].base.gray),
                },
            },
            white: {
                text: color[t].base.white,
                opacity: {
                    low: getOpacity(color[t].base.white, 0.5),
                    medium: getOpacity(color[t].base.white, 0.24),
                    high: getOpacity(color[t].base.white),
                },
            },
            black: {
                text: color[t].base.black,
                opacity: {
                    low: getOpacity(color[t].base.black, 0.5),
                    medium: getOpacity(color[t].base.black, 0.24),
                    high: getOpacity(color[t].base.black),
                },
            },
            blue: {
                text: color[t].base.blue,
                opacity: {
                    low: getOpacity(color[t].base.blue, 0.5),
                    medium: getOpacity(color[t].base.blue, 0.24),
                    high: getOpacity(color[t].base.blue),
                },
            },
            purple: {
                text: color[t].base.purple,
                opacity: {
                    low: getOpacity(color[t].base.purple, 0.5),
                    medium: getOpacity(color[t].base.purple, 0.24),
                    high: getOpacity(color[t].base.purple),
                },
            },
            green: {
                text: color[t].base.green,
                opacity: {
                    low: getOpacity(color[t].base.green, 0.5),
                    medium: getOpacity(color[t].base.green, 0.24),
                    high: getOpacity(color[t].base.green),
                },
            },
        },
    };
    return text;
};
