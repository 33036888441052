/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorEnum,
    ColorEnumFromJSON,
    ColorEnumFromJSONTyped,
    ColorEnumToJSON,
} from './ColorEnum';
import {
    IconNameEnum,
    IconNameEnumFromJSON,
    IconNameEnumFromJSONTyped,
    IconNameEnumToJSON,
} from './IconNameEnum';
import {
    PortfolioTypeEnum,
    PortfolioTypeEnumFromJSON,
    PortfolioTypeEnumFromJSONTyped,
    PortfolioTypeEnumToJSON,
} from './PortfolioTypeEnum';

/**
 * 
 * @export
 * @interface CreatePortfolioDto
 */
export interface CreatePortfolioDto {
    /**
     * 
     * @type {IconNameEnum}
     * @memberof CreatePortfolioDto
     */
    iconName: IconNameEnum;
    /**
     * 
     * @type {ColorEnum}
     * @memberof CreatePortfolioDto
     */
    colorName: ColorEnum;
    /**
     * 
     * @type {PortfolioTypeEnum}
     * @memberof CreatePortfolioDto
     */
    type: PortfolioTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePortfolioDto
     */
    name: string;
}

export function CreatePortfolioDtoFromJSON(json: any): CreatePortfolioDto {
    return CreatePortfolioDtoFromJSONTyped(json, false);
}

export function CreatePortfolioDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePortfolioDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iconName': IconNameEnumFromJSON(json['iconName']),
        'colorName': ColorEnumFromJSON(json['colorName']),
        'type': PortfolioTypeEnumFromJSON(json['type']),
        'currencyCode': json['currencyCode'],
        'name': json['name'],
    };
}

export function CreatePortfolioDtoToJSON(value?: CreatePortfolioDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iconName': IconNameEnumToJSON(value.iconName),
        'colorName': ColorEnumToJSON(value.colorName),
        'type': PortfolioTypeEnumToJSON(value.type),
        'currencyCode': value.currencyCode,
        'name': value.name,
    };
}

