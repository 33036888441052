import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconShowPassword: React.FC = () => (
    <Icon viewBox="0 0 64 64" fill="none">
        <path
            d="M32 14C19.7 14 9.2 23.3 6 32c3.2 8.7 13.7 18 26 18s22.8-9.3 26-18c-3.2-8.7-13.7-18-26-18zm0 30c-7.2 0-13-5.8-13-13s5.8-13 13-13 13 5.8 13 13-5.8 13-13 13z"
            fill="currentColor"
            strokeWidth="1"
        />
        <circle cx="32" cy="32" r="5" fill="currentColor" strokeWidth="1" />
    </Icon>
);
