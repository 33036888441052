import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ResetPasswordDialogMobxDto } from '../../../models/mobx/dtos/auth/reset-password-dialog-mobx-dto';
import { FormItem } from '../../app/form/item/item';
import { Button } from '../../app/form/button/button';
import { PasswordField } from '../../app/form/textfield/password-field';

export interface ResetPasswordFormPropsInterface {
    onSubmit: (values: ResetPasswordDialogMobxDto) => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormPropsInterface> = observer((props) => {
    const { t } = useTranslation();
    const { onSubmit } = props;

    const initialValues = new ResetPasswordDialogMobxDto();
    const validationSchema = yup.object({
        password: yup
            .string()
            .required(t('layout.forms.common.passwordValidation.required'))
            .min(8, t('layout.forms.common.passwordValidation.tooShort'))
            .max(20, t('layout.forms.common.passwordValidation.tooLong'))
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, t('layout.forms.common.passwordValidation.valid')),
        passwordConfirm: yup
            .string()
            .required(t('layout.forms.common.passwordConfirmValidation'))
            .oneOf([yup.ref('password'), null], t('layout.forms.common.passwordConfirmValidationEquality')),
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, handleChange, touched, handleBlur, errors, handleSubmit }) => (
                <>
                    <FormItem>
                        <PasswordField
                            fullWidth
                            name="password"
                            label={t('layout.forms.common.passwordField')}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.password && errors.password ? errors.password : ''}
                            error={Boolean(touched.password && errors.password)}
                        />
                    </FormItem>
                    <FormItem>
                        <PasswordField
                            fullWidth
                            name="passwordConfirm"
                            label={t('layout.forms.signUp.confirmPasswordField')}
                            value={values.passwordConfirm}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : ''}
                            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                        />
                    </FormItem>
                    <FormItem>
                        <Button
                            block
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            variant="primary"
                        >
                            {t('layout.forms.resetPassword.saveNewPassword')}
                        </Button>
                    </FormItem>
                </>
            )}
        </Formik>
    );
});
