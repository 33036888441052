/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestmentBySymbolDto
 */
export interface InvestmentBySymbolDto {
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    investmentValue: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    averageBuyPriceInSymbolCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    averageBuyPrice: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    profit: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentBySymbolDto
     */
    profitPercentage: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentBySymbolDto
     */
    symbolId: string;
}

export function InvestmentBySymbolDtoFromJSON(json: any): InvestmentBySymbolDto {
    return InvestmentBySymbolDtoFromJSONTyped(json, false);
}

export function InvestmentBySymbolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentBySymbolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investmentValue': json['investmentValue'],
        'amount': json['amount'],
        'value': json['value'],
        'averageBuyPriceInSymbolCurrency': json['averageBuyPriceInSymbolCurrency'],
        'averageBuyPrice': json['averageBuyPrice'],
        'profit': json['profit'],
        'profitPercentage': json['profitPercentage'],
        'symbolId': json['symbolId'],
    };
}

export function InvestmentBySymbolDtoToJSON(value?: InvestmentBySymbolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investmentValue': value.investmentValue,
        'amount': value.amount,
        'value': value.value,
        'averageBuyPriceInSymbolCurrency': value.averageBuyPriceInSymbolCurrency,
        'averageBuyPrice': value.averageBuyPrice,
        'profit': value.profit,
        'profitPercentage': value.profitPercentage,
        'symbolId': value.symbolId,
    };
}

